import { PrintService } from '@app/core';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';
import { OltBaseViewComponent, OltConfigServiceBase } from '@outerlimitstech/ngx-app-core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends OltBaseViewComponent implements OnInit {

  public constructor(
    private configService: OltConfigServiceBase,
    private printService: PrintService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title) { super(); }



  ngOnInit(): void {
    super.subscribeTitleChange(this.router, this.activatedRoute, this.titleService, this.configService);
  }

  get printingDocument(): boolean {
    return this.printService.isPrinting;
  }
}
