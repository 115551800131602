import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FullLayoutComponent } from './core/views/full-layout/full-layout.component';
import { NoauthLayoutComponent } from './core/views/noauth-layout/noauth-layout.component';
import { SharedModule } from './shared';
import { NgxPermissionsService, NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { NavBarComponent } from './core/views/full-layout/nav-bar/nav-bar.component';
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs';
import { ApplicationErrorComponent } from './core/views/application-error/application-error.component';
import { PermissionDeniedComponent } from './core/views/permission-denied/permission-denied.component';
import { ConfigService, ApiService, AuthService, BaseViewComponent, GeneralInterceptor, appSettingsFactory, AppSettingsService } from './core';
import { ChangePasswordComponent } from './core/views/change-password/change-password.component';
import { PrintLayoutComponent } from './core/views/print-layout/print-layout.component';
import { PrintTestComponent } from './core/views/print-layout/print-test/print-test.component';
import { OltAngularDateHttpInterceptor, OltApiService, OltAuthServiceBase, OltConfigServiceBase, OltGlobalErrorHandler } from '@outerlimitstech/ngx-app-core';

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    NoauthLayoutComponent,
    NavBarComponent,
    ApplicationErrorComponent,
    PermissionDeniedComponent,
    BaseViewComponent,
    ChangePasswordComponent,
    PrintLayoutComponent,
    PrintTestComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    BreadcrumbsModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      positionClass: 'toast-top-full-width',
      progressBar: true,
      progressAnimation: 'increasing',
    }),
  ],
  providers: [
    // ToasterService,
    NgxPermissionsService,
    {
      provide: OltApiService,
      useClass: ApiService
    },
    {
      provide: OltConfigServiceBase,
      useClass: ConfigService
    },
    {
      provide: OltAuthServiceBase,
      useClass: AuthService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GeneralInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OltAngularDateHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: OltGlobalErrorHandler
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appSettingsFactory,
      multi: true,
      deps: [AppSettingsService],
    },    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
