import { Injectable } from '@angular/core';
import { CONSTANTS } from '../models/constants';
import { AuthenticationToken } from 'bgcslib';



@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private readonly impersonatingTokenName = `${CONSTANTS.Storage.Token}_orig`;

  constructor() { }

  private saveLocalStorage(name: string, value: any) {
    if (value != null) {
      localStorage.setItem(name, value);
    } else {
      localStorage.removeItem(name);
    }
  }

  get authenticatedUser(): AuthenticationToken | null {
    const storageToken = localStorage.getItem(CONSTANTS.Storage.Token);
    if (storageToken != null) {
      return new AuthenticationToken(JSON.parse(storageToken));
    }
    return null;
  }
  set authenticatedUser(value: AuthenticationToken) {
    this.saveLocalStorage(CONSTANTS.Storage.Token, value != null ? JSON.stringify(value) : null);
  }

  get previousUserName(): string {
    return localStorage.getItem(CONSTANTS.Storage.Username);
  }
  set previousUserName(value: string) {
    this.saveLocalStorage(CONSTANTS.Storage.Username, value);
  }

  get impersonatingOriginalToken(): AuthenticationToken | null {
    const storageToken = localStorage.getItem(this.impersonatingTokenName);
    if (storageToken != null) {
      return new AuthenticationToken(JSON.parse(storageToken));
    }
    return null;
  }
  set impersonatingOriginalToken(value: AuthenticationToken) {
    this.saveLocalStorage(this.impersonatingTokenName, value != null ? JSON.stringify(value) : null);
  }

}
